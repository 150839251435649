import axios from "@/api/baseRequest.js";

//根据条件列出时间线
export const apiListTimelineByCondition = (token, formData) =>
    axios.post("/api/v1/admin/timeline/list_by_condition", formData, {
        headers: {
            "token": token
        }
    });

//新增时间线
export const apiAddTimeline = (token, formData) =>
    axios.post("/api/v1/admin/timeline/add", formData, {
        headers: {
            "token": token
        }
    });

//根据ID获取时间线
export const apiGetTimelineById = function (token, id) {
    return axios.get("/api/v1/admin/timeline/get_by_id", {
        headers: {
            "token": token
        },
        params: {
            "id": id
        }
    });
}

//更新时间线
export const apiUpdateTimeline = (token, formData) =>
    axios.post("/api/v1/admin/timeline/update", formData, {
        headers: {
            "token": token
        }
    });

//删除时间线
export const apiDeleteTimeline = (token, formData) =>
    axios.post("/api/v1/admin/timeline/delete_by_id", formData, {
        headers: {
            "token": token
        }
    });